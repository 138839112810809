.container-content {
  place-content: center space-evenly;
  align-items: center;
  display: flex;
  flex: 1 0 0px;
  flex-flow: row nowrap;
  height: min-content;
  overflow: visible;
  padding: 80px 0px;
  position: relative;
  width: 1px;
}

.popup-contact-sales {
  backdrop-filter: blur(40px);
  opacity: 1;
  transform: perspective(1200px) translateX(0px) translateY(0px) scale(1)
    rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0px);
  place-content: flex-start center;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  border-radius: 40px;
  box-shadow: rgb(0, 0, 0) 0px 1px 70px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 10px;
  height: min-content;
  max-width: 620px;
  overflow: visible;
  padding: 40px;
  pointer-events: auto;
  position: relative;
  width: 100%;
  z-index: 10;
}

.form-grid-contact-sales {
  grid-template-rows: max-content 1fr max-content;
  grid-template-columns: 1fr;
  appearance: none;
  display: inline-block;
  width: 100%;
  line-height: 1.4em;
  outline: none;
  border: medium none;
  font-family: 'Jost Regular', 'Jost', 'Inter', system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  font-size: 18px;
  /* color: rgb(255, 255, 255); */
  box-shadow: transparent 0px 0px 0px 1px inset;
}

.form-grid-contact-sales input::placeholder {
  /* color: rgba(255, 255, 255, 0.4); */
}

.bg-contact-sales-submit-button {
  background: rgb(95, 56, 194) !important;
}
