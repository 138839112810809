button.colored {
  background: linear-gradient(
    247.24deg,
    rgb(0, 255, 255) 0%,
    rgb(110, 87, 255) 49.3969%,
    rgb(188, 0, 255) 100%
  );
}

button.gray {
  background: linear-gradient(
    242deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

button.gray:hover {
  background: linear-gradient(
    242deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  /* background: red; */
}

button.lightgray {
  background: linear-gradient(
    242deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

button.lightgray:hover {
  background: linear-gradient(
    242deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
